<!-- <ion-button *ngIf="access" class="logout" (click)="logout()">Logout</ion-button> -->
<ion-tabs *ngIf="access" class="tabs">
   <ion-tab-bar slot="top">
      <ion-tab-button tab="data">
         <ion-icon name="analytics"></ion-icon>
         <ion-label>Data</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="logs">
         <ion-icon name="code"></ion-icon>
         <ion-label>Logs</ion-label>
      </ion-tab-button>
      <ion-tab-button (click)="logout()">
         <ion-icon name="log-out"></ion-icon>
         <ion-label>Logout</ion-label>
      </ion-tab-button>
   </ion-tab-bar>
</ion-tabs>
<ion-content *ngIf="!access" class="holder">
   <ion-button (click)="presentAlertPrompt()" size='small' class="btn-login">Login</ion-button>
   <div class="holder">
      <div class="holderInfo">
         <strong>Ready to create an experiment?</strong>
         <p>Ask TPF or Nicolas for a connection to the central database...</p>
      </div>
   </div>
</ion-content>