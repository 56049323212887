var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModalController, AlertController } from '@ionic/angular';
import { Http, Headers } from '../../../node_modules/@angular/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "@ionic/angular";
//ng build --prod --source-map
var UtilsService = /** @class */ (function () {
    function UtilsService(http, modalControler, alertController) {
        this.http = http;
        this.modalControler = modalControler;
        this.alertController = alertController;
        // public API_ENDPOINT = 'http://localhost:3000';
        this.API_ENDPOINT = 'https://entw-experiments.psy.unibe.ch/entw-backend'; // ENTW server
    }
    UtilsService.prototype.createHeader = function () {
        var head = new Headers();
        this.createAuthorizationHeader(head);
        var res = { headers: head, withCredentials: true };
        return res;
    };
    UtilsService.prototype.login = function (data, callback) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.http
                    .post(this.API_ENDPOINT + "/login", data, this.createHeader())
                    .subscribe(function (response) {
                    callback(true);
                }, function (error) {
                    callback(false);
                    alert("Wrong login!");
                });
                return [2 /*return*/];
            });
        });
    };
    UtilsService.prototype.logout = function () {
        this.http
            .get(this.API_ENDPOINT + "/logOut", this.createHeader())
            .subscribe(function (response) {
        }, function (error) {
        });
    };
    UtilsService.prototype.closeModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalControler.dismiss(null, undefined)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.prototype.createAuthorizationHeader = function (head) {
        head.append("Authorization", "Basic " +
            btoa("a20e6aca-ee83-44bc-8033-b41f3078c2b6:c199f9c8-0548-4be7-9655-7ef7d7bf9d20"));
        head.append("Content-Type", "application/json");
        return head;
    };
    UtilsService.prototype.presentAlertPrompt = function (error, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: 'Info',
                            message: error ? error : "Upload successfull!",
                            buttons: [{
                                    text: 'OK',
                                    handler: function () {
                                        if (callback) {
                                            callback();
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.prototype.presentAlertConfirm = function (msg, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: 'Confirm!',
                            message: msg,
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: function () {
                                    }
                                }, {
                                    text: 'OK',
                                    handler: function () {
                                        callback();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.prototype.getLogs = function (param, callback) {
        var _this = this;
        this.http
            .post(this.API_ENDPOINT + "/getLogs", param, this.createHeader())
            .subscribe(function (response) {
            callback(response.json());
        }, function (error) {
            _this.presentAlertPrompt("Error!", undefined);
        });
    };
    UtilsService.prototype.getData = function (param, callback) {
        var _this = this;
        this.http
            .post(this.API_ENDPOINT + "/getData", param, this.createHeader())
            .subscribe(function (response) {
            callback(response.json());
        }, function (error) {
            _this.presentAlertPrompt("Error!", undefined);
        });
    };
    UtilsService.prototype.prepareDataFromSQL = function (data, excludeColumns, options) {
        var columns = [];
        if (data.length > 0) {
            for (var key in data[0]) {
                if (!excludeColumns.includes(key)) {
                    var col = {
                        headerName: key,
                        field: key,
                        sortable: true,
                        filter: true
                    };
                    if (key == 'id') {
                        col['checkboxSelection'] = true;
                    }
                    if (options && options[key]) {
                        for (var opt in options[key]) {
                            if (options[key].hasOwnProperty(opt)) {
                                col[opt] = options[key][opt];
                            }
                        }
                    }
                    ;
                    columns.push(col);
                }
            }
            return {
                columns: columns,
                cells: data
            };
        }
    };
    UtilsService.prototype.getCurrentUser = function (callback) {
        this.http
            .get(this.API_ENDPOINT + "/sessionUser", this.createHeader())
            .subscribe(function (response) {
            callback(true);
        }, function (error) {
            callback(false);
        });
    };
    UtilsService.prototype.exportFiles = function (dataFiles, fileType) {
        dataFiles.forEach(function (file) {
            var download_link = document.createElement('a');
            download_link.download = file['file_name'] + '.' + fileType;
            download_link.href = 'data:text/plain;charset=utf-16,' + file[fileType];
            download_link.click(); // this will trigger the dialog window
            download_link.remove();
        });
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.inject(i1.Http), i0.inject(i2.ModalController), i0.inject(i2.AlertController)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
