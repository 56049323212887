import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Routes, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { LoggerService } from './services/logger.service';
import { UtilsService } from './services/utils.service';
import { TabDataComponent } from './tab-data/tab-data.component';
import { TabLogsComponent } from './tab-logs/tab-logs.component';
import { TabsComponent } from './tabs/tabs.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule, Http } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import {FileUploadModule} from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

const routes: Routes = [

   { path: '', redirectTo: 'data', pathMatch: 'full' },
   { path: 'data', component: TabDataComponent },
   { path: 'logs', component: TabLogsComponent }
];

@Injectable()
export class ErrorsHandler implements ErrorHandler {
   constructor(private loggerService: LoggerService) { }

   handleError(error: Error) {
      // Do whatever you like with the error (send it to the server?)
      // And log it to the console
      // this.loggerService.log(error);
   }
}

@NgModule({
   declarations: [
      AppComponent,
      TabDataComponent,
      TabLogsComponent,
      TabsComponent,
   ],
   entryComponents: [
   ],
   imports: [
      FormsModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpModule,
      CalendarModule,
      HttpClientModule,
      FileUploadModule,
      AgGridModule.withComponents([]),
      IonicModule.forRoot(),
      RouterModule.forRoot(
         routes,
         { onSameUrlNavigation: 'reload' }
         //{ enableTracing: true } // <-- debugging purposes only
      )
   ],
   providers: [
      StatusBar,
      SplashScreen,
      LoggerService,
      UtilsService,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
