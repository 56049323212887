import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { AlertController } from '@ionic/angular';

@Component({
   selector: 'app-tabs',
   templateUrl: './tabs.component.html',
   styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

   access = false;

   constructor(private utils: UtilsService, public alertController: AlertController) { }

   ngOnInit() {
      this.utils.getCurrentUser((x) => this.isLoggedIn(x));
   }

   isLoggedIn(val) {
      this.access = val;
      if (!val) {
         this.presentAlertPrompt();
      }
   }

   logout() {
      this.access = false;
      this.utils.logout();
   }

   async presentAlertPrompt() {
      const alert = await this.alertController.create({
         header: 'Login!',
         inputs: [
            {
               name: 'userName',
               type: 'text',
               value: '',
               placeholder: 'User name'
            },
            {
               name: 'password',
               type: 'password',
               value: '',
               placeholder: 'Password'
            }
         ],
         buttons: [
            {
               text: 'Cancel',
               role: 'cancel',
               cssClass: 'secondary',
               handler: () => {
                  console.log('Confirm Cancel');
               }
            }, {
               text: 'Ok',
               handler: data => {
                  this.utils.login(data, (x) => { this.setAcces(x) });
               }
            }
         ]
      });

      await alert.present();
   }

   public setAcces(val) {
      this.access = val;
   }

}
