/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "./tabs/tabs.component.ngfactory";
import * as i4 from "./tabs/tabs.component";
import * as i5 from "./services/utils.service";
import * as i6 from "./app.component";
import * as i7 from "@ionic-native/splash-screen/ngx/index";
import * as i8 from "@ionic-native/status-bar/ngx/index";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-app", [], null, null, null, i1.View_ɵa_0, i1.RenderType_ɵa)), i0.ɵdid(1, 49152, null, 0, i2.ɵa, [i0.ChangeDetectorRef, i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-tabs", [], null, null, null, i3.View_TabsComponent_0, i3.RenderType_TabsComponent)), i0.ɵdid(3, 114688, null, 0, i4.TabsComponent, [i5.UtilsService, i2.AlertController], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i6.AppComponent, [i2.Platform, i7.SplashScreen, i8.StatusBar], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
