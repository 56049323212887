import { ModalController, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Http, Headers } from '../../../node_modules/@angular/http';
import * as $ from 'jquery';
import { WebLogin, UserLogsRequest, FileType } from '../interface';
import { DataRequest } from './../interface';

//ng build --prod --source-map

@Injectable({
	providedIn: 'root'
})

export class UtilsService {

	// public API_ENDPOINT = 'http://localhost:3000';
	public API_ENDPOINT = 'https://entw-experiments.psy.unibe.ch/entw-backend'; // ENTW server

	constructor(public http: Http, private modalControler: ModalController, private alertController: AlertController) {
	}

	private createHeader() {
		let head = new Headers();
		this.createAuthorizationHeader(head);
		let res = { headers: head, withCredentials: true };
		return res;
	}

	public async login(data: WebLogin, callback) {
		this.http
			.post(this.API_ENDPOINT + "/login", data, this.createHeader())
			.subscribe(
				response => {
					callback(true);
				},
				error => {
					callback(false);
					alert("Wrong login!");
				}
			);
	}

	public logout() {
		this.http
			.get(this.API_ENDPOINT + "/logOut", this.createHeader())
			.subscribe(
				response => {

				},
				error => {

				}
			);
	}

	public async closeModal() {
		await this.modalControler.dismiss(null, undefined);
	}

	public createAuthorizationHeader(head) {
		head.append(
			"Authorization",
			"Basic " +
			btoa(
				"a20e6aca-ee83-44bc-8033-b41f3078c2b6:c199f9c8-0548-4be7-9655-7ef7d7bf9d20"
			)
		);
		head.append("Content-Type", "application/json");
		return head;
	}

	public async presentAlertPrompt(error, callback) {
		const alert = await this.alertController.create({
			header: 'Info',
			message: error ? error : "Upload successfull!",
			buttons: [{
				text: 'OK',
				handler: () => {
					if (callback) {
						callback();
					}
				}
			}]
		});

		await alert.present();
	}

	async presentAlertConfirm(msg, callback) {
		const alert = await this.alertController.create({
			header: 'Confirm!',
			message: msg,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
					}
				}, {
					text: 'OK',
					handler: () => {
						callback();
					}
				}
			]
		});

		await alert.present();
	}

	public getLogs(param: UserLogsRequest, callback) {
		this.http
			.post(this.API_ENDPOINT + "/getLogs", param, this.createHeader())
			.subscribe(
				response => {
					callback(response.json());
				},
				error => {
					this.presentAlertPrompt("Error!", undefined);
				}
			);
	}

	public getData(param: DataRequest, callback) {
		this.http
			.post(this.API_ENDPOINT + "/getData", param, this.createHeader())
			.subscribe(
				response => {
					callback(response.json());
				},
				error => {
					this.presentAlertPrompt("Error!", undefined);
				}
			);
	}

	public prepareDataFromSQL(data, excludeColumns, options) {
		const columns = [];
		if (data.length > 0) {
			for (let key in data[0]) {
				if (!excludeColumns.includes(key)) {
					const col = {
						headerName: key,
						field: key,
						sortable: true,
						filter: true
					};
					if (key == 'id') {
						col['checkboxSelection'] = true;
					}
					if (options && options[key]) {
						for (const opt in options[key]) {
							if (options[key].hasOwnProperty(opt)) {
								col[opt] = options[key][opt];
							}
						}
					};
					columns.push(col);
				}
			}
			return {
				columns: columns,
				cells: data
			}
		}
	}

	public getCurrentUser(callback) {
		this.http
			.get(this.API_ENDPOINT + "/sessionUser", this.createHeader())
			.subscribe(
				response => {
					callback(true);
				},
				error => {
					callback(false);
				}
			);
	}

	public exportFiles(dataFiles: any, fileType: FileType) {
		dataFiles.forEach(file => {
			const download_link: HTMLAnchorElement = document.createElement('a');
			download_link.download = file['file_name'] + '.' + fileType;
			download_link.href = 'data:text/plain;charset=utf-16,' + file[fileType];
			download_link.click(); // this will trigger the dialog window
			download_link.remove();
		});
	}

}
