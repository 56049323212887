<ion-content class="holder">
   <div class = 'controlsHolder'>
      <div class="controls">
         <ion-label class="label">From: </ion-label>
         <p-calendar [(ngModel)]="dateFrom" [showIcon]="true" class="expand" dateFormat="dd/mm/yy"
            [inputStyle]="{'width':'calc(100% - 2.357em)'}" [style]="{'width':'100%'}"></p-calendar>
      </div>
      <div class="controls">
         <ion-label class="label">To: </ion-label>
         <p-calendar [(ngModel)]="dateTo" dateFormat="dd/mm/yy" [showIcon]="true" class="expand"
            [inputStyle]="{'width':'calc(100% - 2.357em)'}" [style]="{'width':'100%'}"></p-calendar>
      </div>
      <div class='buttons'>
         <ion-button (click)="getLogsData()" size='small'>Refresh</ion-button>
         <ion-button (click)="exportCSV()" size='small'>Export CSV</ion-button>
      </div>
   </div>
   <ag-grid-angular *ngIf="columnDefs.length > 0" class="ag-theme-balham grid-height" [rowData]="rowData"
      [suppressCellSelection]="true" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)">
   </ag-grid-angular>
</ion-content>