import { Component, OnInit } from '@angular/core';
import { UtilsService } from './../services/utils.service';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { FileType } from '../interface';

@Component({
	selector: 'app-tab-data',
	templateUrl: './tab-data.component.html',
	styleUrls: ['./tab-data.component.scss']
})
export class TabDataComponent implements OnInit {
	rowData = [];
	columnDefs = [];
	gridApi;
	private gridColumnApi;
	private rowSelection;
	dateFrom;
	dateTo;

	constructor(private utils: UtilsService, private modalCtrl: ModalController) {
		this.rowSelection = "multiple";
	}

	ngOnInit() {
		this.dateFrom = new Date();
		this.dateTo = new Date();
		this.getData();
	}

	getData() {
		this.rowData = [];
		this.columnDefs = [];
		var datePipe = new DatePipe('en-US');
		var dFrom = datePipe.transform(this.dateFrom, 'dd/MM/yyyy');
		var dTo = datePipe.transform(this.dateTo, 'dd/MM/yyyy');
		this.utils.getData({ dateFrom: dFrom, dateTo: dTo }, (data) => this.loadData(data));
	}

	loadData(data) {
		let res = this.utils.prepareDataFromSQL(data, ["mobile"], { 'file_name': { 'width': 500 }, 'id': { 'width': 100 } });
		if (res) {
			this.columnDefs = res.columns;
			this.rowData = res.cells;
		}
	}

	exportCSV() {
		var params = {
			skipHeader: false,
			columnGroups: false,
			skipFooters: false,
			skipGroups: false,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			suppressQuotes: false,
			fileName: "Data_" + new Date().toString(),
			columnSeparator: ","
		};
		if (this.rowData.length > 0) {
			this.gridApi.exportDataAsCsv(params);
		} else {
			this.utils.presentAlertPrompt("Empty data!", undefined);
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	exportFiles(fileType: FileType) {
		if (this.gridApi && this.gridApi.getSelectedRows().length > 0) {
			this.utils.exportFiles(this.gridApi.getSelectedRows(), fileType);
		}else{
			this.utils.presentAlertPrompt('Please select some records!',()=>{});
		}
	}

}
