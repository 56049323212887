import { Component, OnInit } from '@angular/core';
import { UtilsService } from './../services/utils.service';
import { DatePipe } from '@angular/common';

@Component({
   selector: 'app-tab-logs',
   templateUrl: './tab-logs.component.html',
   styleUrls: ['./tab-logs.component.scss']
})
export class TabLogsComponent implements OnInit {
   rowData = [];
   columnDefs = [];
   gridApi;
   dateFrom;
   dateTo;

   constructor(private utils: UtilsService) { }

   ngOnInit() {
      this.dateFrom = new Date();
      this.dateTo = new Date();
      this.getLogsData();
   }

   getLogsData() {
      this.rowData = [];
      this.columnDefs = [];
      var datePipe = new DatePipe('en-US');
      var dFrom = datePipe.transform(this.dateFrom, 'dd/MM/yyyy');
      var dTo = datePipe.transform(this.dateTo, 'dd/MM/yyyy');
      this.utils.getLogs({ dateFrom: dFrom, dateTo: dTo }, (data) => this.loadData(data));
   }

   loadData(data) {
      let res = this.utils.prepareDataFromSQL(data, [], { 'log': { 'width': 1000 } });
      if (res) {
         this.columnDefs = res.columns;
         this.rowData = res.cells;
      }
   }

   exportCSV() {
      var params = {
         skipHeader: false,
         columnGroups: false,
         skipFooters: false,
         skipGroups: false,
         skipPinnedTop: false,
         skipPinnedBottom: false,
         allColumns: true,
         onlySelected: false,
         suppressQuotes: false,
         fileName: "Logs_" + new Date().toString(),
         columnSeparator: ","
      };
      if (this.rowData.length > 0) {
         this.gridApi.exportDataAsCsv(params);
      } else {
         this.utils.presentAlertPrompt("Empty data!", undefined);
      }
   }

   onGridReady(params) {
      this.gridApi = params.api;
   }

}
