/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./tabs.component";
import * as i6 from "../services/utils.service";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: {} });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "ion-tabs", [["class", "tabs"]], null, [[null, "ionRouterOutletActivated"], [null, "ionTabButtonClick"]], function (_v, en, $event) { var ad = true; if (("ionRouterOutletActivated" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPageSelected($event.detail) !== false);
        ad = (pd_0 && ad);
    } if (("ionTabButtonClick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).select($event.detail.tab) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ɵcv_0, i2.RenderType_ɵcv)), i1.ɵdid(1, 49152, null, 1, i3.ɵcv, [i3.ɵcz], null, null), i1.ɵqud(335544320, 1, { tabBar: 0 }), (_l()(), i1.ɵeld(3, 0, null, 0, 22, "ion-tab-bar", [["slot", "top"]], null, null, null, i2.View_ɵcn_0, i2.RenderType_ɵcn)), i1.ɵdid(4, 49152, [[1, 4]], 0, i3.ɵcn, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "ion-tab-button", [["tab", "data"]], null, null, null, i2.View_ɵco_0, i2.RenderType_ɵco)), i1.ɵdid(6, 49152, null, 0, i3.ɵco, [i1.ChangeDetectorRef, i1.ElementRef], { tab: [0, "tab"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "ion-icon", [["name", "analytics"]], null, null, null, i2.View_ɵx_0, i2.RenderType_ɵx)), i1.ɵdid(8, 49152, null, 0, i3.ɵx, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_ɵbi_0, i2.RenderType_ɵbi)), i1.ɵdid(10, 49152, null, 0, i3.ɵbi, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Data"])), (_l()(), i1.ɵeld(12, 0, null, 0, 6, "ion-tab-button", [["tab", "logs"]], null, null, null, i2.View_ɵco_0, i2.RenderType_ɵco)), i1.ɵdid(13, 49152, null, 0, i3.ɵco, [i1.ChangeDetectorRef, i1.ElementRef], { tab: [0, "tab"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "ion-icon", [["name", "code"]], null, null, null, i2.View_ɵx_0, i2.RenderType_ɵx)), i1.ɵdid(15, 49152, null, 0, i3.ɵx, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_ɵbi_0, i2.RenderType_ɵbi)), i1.ɵdid(17, 49152, null, 0, i3.ɵbi, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Logs"])), (_l()(), i1.ɵeld(19, 0, null, 0, 6, "ion-tab-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵco_0, i2.RenderType_ɵco)), i1.ɵdid(20, 49152, null, 0, i3.ɵco, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(21, 0, null, 0, 1, "ion-icon", [["name", "log-out"]], null, null, null, i2.View_ɵx_0, i2.RenderType_ɵx)), i1.ɵdid(22, 49152, null, 0, i3.ɵx, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_ɵbi_0, i2.RenderType_ɵbi)), i1.ɵdid(24, 49152, null, 0, i3.ɵbi, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Logout"]))], function (_ck, _v) { var currVal_0 = "data"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "analytics"; _ck(_v, 8, 0, currVal_1); var currVal_2 = "logs"; _ck(_v, 13, 0, currVal_2); var currVal_3 = "code"; _ck(_v, 15, 0, currVal_3); var currVal_4 = "log-out"; _ck(_v, 22, 0, currVal_4); }, null); }
function View_TabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-content", [["class", "holder"]], null, null, null, i2.View_ɵp_0, i2.RenderType_ɵp)), i1.ɵdid(1, 49152, null, 0, i3.ɵp, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-button", [["class", "btn-login"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.presentAlertPrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵf_0, i2.RenderType_ɵf)), i1.ɵdid(3, 49152, null, 0, i3.ɵf, [i1.ChangeDetectorRef, i1.ElementRef], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["Login"])), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "holderInfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ready to create an experiment?"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ask TPF or Nicolas for a connection to the central database..."]))], function (_ck, _v) { var currVal_0 = "small"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.access; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.access; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tabs", [], null, null, null, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 114688, null, 0, i5.TabsComponent, [i6.UtilsService, i3.AlertController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabsComponentNgFactory = i1.ɵccf("app-tabs", i5.TabsComponent, View_TabsComponent_Host_0, {}, {}, []);
export { TabsComponentNgFactory as TabsComponentNgFactory };
